import { createReducer, on } from "@ngrx/store";
import { SpinnerActions } from "./../actions";

export interface SpinnerState {
    active: number;
    actionsInProgress: string[];
}

const initialState: SpinnerState = {
    active: 0,
    actionsInProgress: []
};

export const SpinnerReducer = createReducer<SpinnerState>(
    initialState,
    on(SpinnerActions.showSpinner, (state, action): SpinnerState => {
        const isActionAlreadyInProgress = state.actionsInProgress.filter(
            (currentAction: any) => currentAction === action.nameOfOperationInProcess
        ).length;

        // If the action in already in progress and is registered
        // we don't modify the state
        if (isActionAlreadyInProgress) {
            return state;
        }
        // Adding the action type in our actionsInProgress array
        const newActionsInProgress = [
            ...state.actionsInProgress,
            action.nameOfOperationInProcess
        ];

        return {
            ...state,
            active: newActionsInProgress.length,
            actionsInProgress: newActionsInProgress
        };

        // return Object.assign(state, {
        //     active: newActionsInProgress.length,
        //     actionsInProgress: newActionsInProgress
        // });

    }),
    on(SpinnerActions.hideSpinner, (state, action): SpinnerState => {
        // We remove trigger action from actionsInProgress array
        const newActionsInProgress = action.nameOfOperationInProcess
            ? state.actionsInProgress.filter(
                (currentAction: any) =>
                    currentAction !== action.nameOfOperationInProcess
            )
            : state.actionsInProgress;

        // return Object.assign(state, {
        //     actionsInProgress: newActionsInProgress,
        //     active: state.active > 0 ? newActionsInProgress.length : 0
        // });

        return {
            ...state,
            actionsInProgress: newActionsInProgress,
            active: state.active > 0 ? newActionsInProgress.length : 0
        };
    })
)