import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ErrorState } from "./error-reducer";

const getErrorFeatureState = createFeatureSelector<ErrorState>('errors');

export const getError = createSelector(
    getErrorFeatureState,
    state => { 
        return state.errorMessage;
    }
);
