import { createReducer, on } from "@ngrx/store";
import { ErrorActions } from "./../actions";

export interface ErrorState {
    errorMessage: string;    
}

const initialState: ErrorState = {
    errorMessage: ""
};

export const ErrorReducer = createReducer<ErrorState>(
    initialState,
    on(ErrorActions.showError, (state, action): ErrorState => {     
        return {
            ...state,
            errorMessage: action.errorMessage            
        };
    })    
)