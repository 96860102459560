import { createAction, props } from "@ngrx/store";

export const showSpinner = createAction(
    '[App Spinner] Show',       
    props<{ nameOfOperationInProcess: string }>() 
);
export const hideSpinner = createAction(
    '[App Spinner] Hide',
    props<{ nameOfOperationInProcess: string }>() 
);
