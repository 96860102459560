import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './interceptors/error-interceptor';
import { EnsureModuleLoadedOnceGuard } from './validations/ensure-module-loaded-once.guard';


@NgModule({
  declarations: [      
  ],
  imports: [
    HttpClientModule    
  ],
  providers: [ 
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ]
})
export class SharedCoreModule extends EnsureModuleLoadedOnceGuard { 
  constructor (@Optional() @SkipSelf() parentModule: SharedCoreModule) {
    super(parentModule);
  }
}
