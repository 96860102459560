import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { autenticacionResponse } from './auth';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private router: Router, private httpClient : HttpClient,  private toastr: ToastrService) { }

  apiURL =  environment.api_url + 'api'

  async loginByAuth({cuentausuario, password}) {
    try {

      var body =  JSON.stringify({ cuentausuario, password });
      console.log(body);

      var response = this.httpClient.post<autenticacionResponse>(this.apiURL + '/authenticate', body, {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        }),
        responseType: 'json'
      } );

      response.subscribe(p => {
        localStorage.setItem('token', p.token);
        this.router.navigate(['/']);
        this.toastr.success('Login success');
      }, error => console.error(error));
    } catch (error) {

      console.log('error');
      console.log(error);
        this.toastr.error(error.message);
    }
}
}
