<app-header></app-header>

<app-menu-sidebar class="sidebar-dark-primary"></app-menu-sidebar>

<div class="content-wrapper">
    <div class="spinner-content" *ngIf="showSpinner">
        <div class="spinner-box">
            <app-spinner></app-spinner>
        </div>
    </div>
    <router-outlet></router-outlet>  
</div>

<app-footer></app-footer>
<app-control-sidebar></app-control-sidebar>
<div id="sidebar-overlay" (click)="onToggleMenuSidebar()"></div>
