import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConstantsHttp } from '../constants';
import { Store } from '@ngrx/store';
import { State } from '@states/app.state';
import { ErrorActions } from '@states/error/actions';
// import { SecurityService } from '../services/security.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    // constructor(private securityService: SecurityService) { }
    constructor(private store: Store<State>,){}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err.status === ConstantsHttp.InternalServerError) {
                    this.store.dispatch(ErrorActions.showError({ errorMessage: "Ocurrio un error interno en la aplicación" }));
                } else if (err.status === ConstantsHttp.Unauthorized) {                    
                    this.store.dispatch(ErrorActions.showError({ errorMessage: "Sesión vendida o no cuenta con autorización" }));
                    //this.securityService.logout();
                    location.reload();
                } else if (err.status === ConstantsHttp.NotFound || err.status === ConstantsHttp.BadRequest) {   
                    return throwError(() => err);                
                } else {
                    this.store.dispatch(ErrorActions.showError({ errorMessage: "Ocurrio un error interno en la aplicación" }));
                }
                return throwError(() => err);
            })
        )
    }
}