import {AppState} from '@/store/state';
import {UiState} from '@/store/ui/state';
import {Component, HostBinding, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppService} from '@services/app.service';
import {Observable} from 'rxjs';

const BASE_CLASSES = 'main-sidebar elevation-4';
@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    @HostBinding('class') classes: string = BASE_CLASSES;
    public ui: Observable<UiState>;
    public user;
    public menu = MENU;

    constructor(
        public appService: AppService,
        private store: Store<AppState>
    ) {}

    ngOnInit() {
        this.ui = this.store.select('ui');
        this.ui.subscribe((state: UiState) => {
            this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
        });
        this.user = "Jose"; //this.appService.user;
    }
}

export const MENU = [
    {
        name: 'Dashboard',
        iconClasses: 'fas fa-tachometer-alt',
        path: ['/'],
        children: [
          {
              name: 'IBC Corregidor',
              iconClasses: 'far fa-address-book',
              path: ['/1']
          }
      ]
    },
    {
      name: 'Membresía',
      iconClasses: 'fas fa-users',
      children: [
          {
              name: 'Miembro',
              iconClasses: 'fas fa-id-card',
              path: ['membership/members']
          },
          {
            name: 'Familias de la Iglesia',
            iconClasses: 'fas fa-users',
            path: ['/membresia/familias']
          },
          {
              name: 'Ministerios',
              iconClasses: 'fas fa-cogs',
              path: ['/membresia/ministerios']
          },
          {
            name: 'Visitaciones',
            iconClasses: 'fas fa-glasses',
            path: ['/membresia/visitaciones']
          },
          {
            name: 'Pasos Completados',
            iconClasses: 'fas fa-list-ol',
            path: ['/membresia/pasos']
          }
      ]
    },
    {
      name: 'Finanzas',
      iconClasses: 'fa fa-chart-line',
      children: [
          {
              name: 'Movimientos',
              iconClasses: 'fa fa-sync',
              path: ['/finance/movements']
          },
          {
            name: 'Diezmos',
            iconClasses: 'fa fa-envelope',
            path: ['/finance/tithes']
        }
      ]
    }
];
