import { Injectable } from "@angular/core";
import { Actions, createEffect } from "@ngrx/effects";
import { SpinnerActions } from "./../actions";
import { filter, map } from "rxjs";

@Injectable()
export class SpinnerEffects {

    constructor(private actions$: Actions) { }

    showSpinner$ = createEffect(() => {
        return this.actions$.pipe(
            filter((action: any) => (action && action.showLoader ? action : null)),
            map((action: any) => SpinnerActions.showSpinner({ nameOfOperationInProcess: action.showLoader } ))
          )
    });

    hideSpinner$ = createEffect(() => {
        return this.actions$.pipe(
            filter((action: any) => (action && action.hideLoader ? action : null)),
            map((action: any) => SpinnerActions.hideSpinner({ nameOfOperationInProcess: action.hideLoader } ))
          )
    });

}