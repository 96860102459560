import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SpinnerState } from "./spinner-reducer";

const getSpinnerFeatureState = createFeatureSelector<SpinnerState>('spinners');

export const isSpinnerActive = createSelector(
    getSpinnerFeatureState,
    state => { 
        return state.active > 0;
    }
);
