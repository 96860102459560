<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark">Dashboard</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Dashboard v1</li>
                </ol>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
      <form id="form0" name="form0" method="post" action="/ihc-war/app/dashboard.xhtml" enctype="application/x-www-form-urlencoded">
        <input type="hidden" name="form0" value="form0">

                    <div class="ui-g dashboard">


                        <div class="ui-g-12 ui-md-12 ui-lg-12">
                            <div class="ui-g-12 ui-md-12 ui-lg-6">
                                <div class="card fadeInDown animated">
                                    <div class="ui-g">
                                        <div class="ui-g-12 ui-md-6 ui-lg-4"><img id="form0:j_idt54" src="/ihc-war/javax.faces.resource/dynamiccontent.properties.xhtml?ln=primefaces&amp;v=6.2.17&amp;pfdrid=332225579ea1d5f6477c46c31bdb38ab&amp;pfdrt=sc&amp;uid=103&amp;pfdrid_c=false&amp;uid=16a4eaee-689a-4ba3-ab30-08d10d43290c" alt="" style="width: 90%">
                                        </div>
                                        <div class="ui-g-12 ui-md-6 ui-lg-8">
                                            <h1>Iglesia Bíblica Cristiana en Pachacámac</h1><table cellspacing="10">
        <tbody>
        <tr>
        <td>
                                                <i class="fa fa-user" style="font-size: 27px; color: #757575"></i></td>
        <td><span style="text-align: left">
                                                    Pastor: Javier Martínez
                                                </span></td>
        </tr>
        <tr>
        <td>
                                                <i class="fa fa-map-marker" style="font-size: 27px; color: #757575"></i></td>
        <td><span style="text-align: left">
                                                    Asoc, Jesús del gran poder, Manchay, Pachacámac.  Lima
                                                    <label id="form0:j_idt64" class="ui-outputlabel ui-widget">Perú</label></span></td>
        </tr>
        <tr>
        <td>
                                                <i class="fa fa-phone" style="font-size: 27px; color: #757575"></i></td>
        <td><span style="text-align: left">
                                                    Teléfono: 948532392
                                                </span></td>
        </tr>
        <tr>
        <td>
                                                <i class="fa fa-envelope" style="font-size: 27px; color: #757575"></i></td>
        <td><span style="text-align: left">
                                                    email: ibc.pachacamac@ibcpach.com
                                                </span></td>
        </tr>
        </tbody>
        </table>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="ui-g-12 ui-md-12 ui-lg-6">
                                <div class="fadeInDown animated card">
                                    <div class="ui-g card colorbox colorbox-1">
                                        <div class="ui-g-4">
                                            <i class="material-icons fa fa-user"></i>
                                        </div>
                                        <div class="ui-g-8">
                                            <span class="colorbox-name">Personas</span>
                                            <span class="colorbox-count">94</span>
                                        </div>
                                    </div>

                                    <div class="ui-g card colorbox colorbox-2" style="margin-top: 10px">
                                        <div class="ui-g-4">
                                            <i class="material-icons fa fa-cogs"></i>
                                        </div>
                                        <div class="ui-g-8">
                                            <span class="colorbox-name">Ministerios</span>
                                            <span class="colorbox-count">5</span>
                                        </div>
                                    </div>
                                    <div class="ui-g card colorbox colorbox-3" style="margin-top: 10px">
                                        <div class="ui-g-4">
                                            <i class="material-icons fa fa-group"></i>
                                        </div>
                                        <div class="ui-g-8">
                                            <span class="colorbox-name">Familias</span>
                                            <span class="colorbox-count">17</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="ui-g-12 ui-md-12 ui-lg-4">
                            <div class="fadeInDown animated card">
                                <div class="ui-g">
                                    <div class="ui-g-12 ui-md-12 ui-lg-12"><div id="form0:j_idt72" class="ui-panelgrid ui-widget ui-panelgrid-blank form-group"><div id="form0:j_idt72_content" class="ui-panelgrid-content ui-widget-content ui-grid ui-grid-responsive"><div class="ui-g"><div class="ui-panelgrid-cell ui-g-12 ui-md-12">
                                            <i class="fa fa-birthday-cake" style="font-size: 26px; color: #757575"> Cumpleaños de Hoy</i></div></div></div></div><div id="form0:j_idt74" class="ui-outputpanel ui-widget" style="height: 300px"></div>
                                    </div>
                                    <div class="ui-g-12 ui-md-12 ui-lg-8">
                                    </div>
                                    <div class="ui-g-12 ui-md-12 ui-lg-4"><button id="form0:j_idt88" name="form0:j_idt88" type="button" class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" style="float: right" onclick="window.open('\/ihc-war\/app\/memb_birthday.xhtml','_self')" role="button" aria-disabled="false"><span class="ui-button-text ui-c">Ver más</span></button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="ui-g-12 ui-md-12 ui-lg-4">
                            <div class="fadeInDown animated card">
                                <div class="ui-g">
                                    <div class="ui-g-12 ui-md-12 ui-lg-12"><div id="form0:j_idt90" class="ui-panelgrid ui-widget ui-panelgrid-blank form-group"><div id="form0:j_idt90_content" class="ui-panelgrid-content ui-widget-content ui-grid ui-grid-responsive"><div class="ui-g"><div class="ui-panelgrid-cell ui-g-12 ui-md-12">
                                            <i class="fa fa-user-plus" style="font-size: 26px; color: #757575"> Últimas Conversiones</i></div></div></div></div><div id="form0:j_idt92" class="ui-outputpanel ui-widget" style="height: 300px">
                                                <div class="ui-g" style="display: block; margin: auto">
                                                    <div class="ui-g-12 ui-md-12 ui-lg-2"><img id="form0:j_idt93:0:j_idt95" src="/ihc-war/javax.faces.resource/dynamiccontent.properties.xhtml?ln=primefaces&amp;v=6.2.17&amp;pfdrid=a15b606a68a168ee8b7ff0f1c59ac1be&amp;pfdrt=sc&amp;uid=11109&amp;pfdrid_c=false&amp;uid=607d4769-c617-4457-a849-d8ccddc94bc5" alt="image" style="border-radius: 50%; margin: auto" width="50">
                                                    </div>
                                                    <div class="ui-g-12 ui-md-12 ui-lg-10"><table>
        <tbody>
        <tr>
        <td>ANTHONY JHOSHUA CUENCA SINCHE</td>
        </tr>
        <tr>
        <td>
                                                            <i class="fa fa-calendar" style="font-size: 12px; color: #757575"> Fecha de conversión:  17/may/2007 </i></td>
        </tr>
        </tbody>
        </table>

                                                    </div>
                                                </div>
                                                <div class="ui-g" style="display: block; margin: auto">
                                                    <div class="ui-g-12 ui-md-12 ui-lg-2"><img id="form0:j_idt93:1:j_idt95" src="/ihc-war/javax.faces.resource/dynamiccontent.properties.xhtml?ln=primefaces&amp;v=6.2.17&amp;pfdrid=a15b606a68a168ee8b7ff0f1c59ac1be&amp;pfdrt=sc&amp;uid=11103&amp;pfdrid_c=false&amp;uid=c74b71ac-6054-40cc-b5fd-0f9420b03cb8" alt="image" style="border-radius: 50%; margin: auto" width="50">
                                                    </div>
                                                    <div class="ui-g-12 ui-md-12 ui-lg-10"><table>
        <tbody>
        <tr>
        <td>JOSE JAVIER MARTINEZ MORON</td>
        </tr>
        <tr>
        <td>
                                                            <i class="fa fa-calendar" style="font-size: 12px; color: #757575"> Fecha de conversión:  03/mar/1998 </i></td>
        </tr>
        </tbody>
        </table>

                                                    </div>
                                                </div></div>
                                    </div>
                                    <div class="ui-g-12 ui-md-12 ui-lg-12"><button id="form0:j_idt103" name="form0:j_idt103" type="button" class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" style="float: right" onclick="window.open('\/ihc-war\/app\/memb_busq_avan.xhtml','_self')" role="button" aria-disabled="false"><span class="ui-button-text ui-c">Ver más</span></button>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div class="ui-g-12 ui-md-12 ui-lg-4">
                            <div class="fadeInDown animated card">
                                <div class="ui-g">
                                    <div class="ui-g-12 ui-md-12 ui-lg-12"><div id="form0:j_idt105" class="ui-panelgrid ui-widget ui-panelgrid-blank form-group"><div id="form0:j_idt105_content" class="ui-panelgrid-content ui-widget-content ui-grid ui-grid-responsive"><div class="ui-g"><div class="ui-panelgrid-cell ui-g-12 ui-md-12">
                                            <i class="fa fa-line-chart" style="font-size: 26px; color: #757575"> Estadísticas de Miembros</i></div></div></div></div><div id="form0:j_idt107" class="ui-outputpanel ui-widget" style="height: 300px">
                                            <div class="ui-g card colorbox colorbox-2">
                                                <div class="ui-g-4">
                                                    <i class="material-icons fa fa-male"></i>
                                                </div>
                                                <div class="ui-g-8">
                                                    <span class="colorbox-name">Hombres (45.35%)</span>
                                                    <span class="colorbox-count">39 </span>
                                                </div>
                                            </div>

                                            <div class="ui-g card colorbox colorbox-3" style="margin-top: 10px">
                                                <div class="ui-g-4">
                                                    <i class="material-icons fa fa-female"></i>
                                                </div>
                                                <div class="ui-g-8">
                                                    <span class="colorbox-name">Mujeres  (54.65%)</span>
                                                    <span class="colorbox-count">47</span>
                                                </div>
                                            </div>

                                            <div class="ui-g card colorbox colorbox-1" style="margin-top: 10px">
                                                <div class="ui-g-4">
                                                    <i class="material-icons fa fa-group"></i>
                                                </div>
                                                <div class="ui-g-8">
                                                    <span class="colorbox-name">Edad Promedio</span>
                                                    <span class="colorbox-count">30.42</span>
                                                </div>
                                            </div></div>
                                    </div>
                                    <div class="ui-g-12 ui-md-12 ui-lg-12"><button id="form0:j_idt110" name="form0:j_idt110" type="button" class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" style="float: right" onclick="window.open('\/ihc-war\/app\/memb_busq_avan.xhtml','_self')" role="button" aria-disabled="false"><span class="ui-button-text ui-c">Ver más</span></button>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div class="ui-g-12 ui-md-12 ui-lg-12">
                            <div class="fadeInDown animated card">
                                <div class="ui-g">
                                    <div class="ui-g-12 ui-md-12 ui-lg-12"><div id="form0:j_idt112" class="ui-panelgrid ui-widget ui-panelgrid-blank form-group"><div id="form0:j_idt112_content" class="ui-panelgrid-content ui-widget-content ui-grid ui-grid-responsive"><div class="ui-g"><div class="ui-panelgrid-cell ui-g-12 ui-md-12">
                                            <i class="fa fa-bar-chart" style="font-size: 26px; color: #757575"> Composición de la Membresía</i></div></div></div></div>
                                    </div>

                                    <div class="ui-g-12 ui-md-12 ui-lg-5"><div id="form0:j_idt115" class="ui-outputpanel ui-widget"><div id="form0:j_idt116" class="ui-chart"><div style="position: absolute; inset: 0px; overflow: hidden; pointer-events: none; visibility: hidden; z-index: -1;" class="chartjs-size-monitor"><div class="chartjs-size-monitor-expand" style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;"><div style="position:absolute;width:1000000px;height:1000000px;left:0;top:0"></div></div><div class="chartjs-size-monitor-shrink" style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;"><div style="position:absolute;width:200%;height:200%;left:0; top:0"></div></div></div><canvas id="form0:j_idt116_canvas" style="width: 485px; height: 400px; display: block;" class="chartjs-render-monitor" width="606" height="500"></canvas></div></div>
                                    </div>

                                    <div class="ui-g-12 ui-md-12 ui-lg-7"><div id="form0:j_idt118" class="ui-outputpanel ui-widget"><div id="form0:j_idt119" class="ui-chart"><div style="position: absolute; inset: 0px; overflow: hidden; pointer-events: none; visibility: hidden; z-index: -1;" class="chartjs-size-monitor"><div class="chartjs-size-monitor-expand" style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;"><div style="position:absolute;width:1000000px;height:1000000px;left:0;top:0"></div></div><div class="chartjs-size-monitor-shrink" style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;"><div style="position:absolute;width:200%;height:200%;left:0; top:0"></div></div></div><canvas id="form0:j_idt119_canvas" style="width: 684px; height: 400px; display: block;" class="chartjs-render-monitor" width="855" height="500"></canvas></div></div>
                                    </div>

                                    <div class="ui-g-12 ui-md-12 ui-lg-12"><button id="form0:j_idt121" name="form0:j_idt121" type="button" class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" style="float: right" onclick="window.open('\/ihc-war\/app\/memb_charts.xhtml','_self')" role="button" aria-disabled="false"><span class="ui-button-text ui-c">Ver más</span></button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <!-- Informacion de grupos -->
                    </div><input type="hidden" name="javax.faces.ViewState" id="j_id1:javax.faces.ViewState:2" value="-7620460532229093922:-7098679106153722113" autocomplete="off">
        </form>
    </div>
    <!-- Main row -->
</section>
<!-- /.content -->
