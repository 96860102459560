export class ConstantsMasterTable {
    public static MEMBER_TYPE = "METY";
    public static GENDER = "GEND";    
    public static CIVIL_STATUS = "CIST";
    public static SOCIAL_GROUP = "SOGR";
    public static MEMBER_STATUS = "MEST";
    public static CONTACT_METHOD = "COMT";
    public static REGISTER = "REGI";
    public static TELEPHONELABEL = "TELA";
}

export class ConstantsConfirmation {
    public static YES = "YES";
    public static NO = "NO";        
}

export class ConstantsHttp {
    public static InternalServerError = 500;
    public static Unauthorized = 401;
    public static NotFound = 404;        
    public static BadRequest = 400;        
}